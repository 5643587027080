<template>
  <div class="logout">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <div class="logout-text" v-bind="attrs" v-on="on">
          {{ $t("logout") }}
        </div>
      </template>

      <v-card>
        <v-card-title>
          {{ $t("logout_confirm_text") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="dialog = false">
            {{ $t("disagree") }}
          </v-btn>

          <v-btn color="#132d7a" @click="logout()">
            <span style="color: #fff">{{ $t("agree") }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
export default {
  name: "LogoutButton",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    logout() {
      sessionStorage.clear();
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          this.$router.push("/");
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  },
};
</script>

<style scoped lang="scss">
.logout {
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  z-index: 50;
  bottom: 10px;
  right: 20px;

  .logout-text {
    margin-left: 5px;
    font-size: 15px;
    line-height: 16px;
    color: #132d7a;
    text-align: center;
  }
}
</style>

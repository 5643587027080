<template>
  <div class="title">   
    <img src="../../assets/public/akicon-logo_yoko.png" width="230px" />
  </div>
</template>

<script>
export default {
  name: "Title",
};
</script>

<style scoped lang="scss">
.title {
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 20px;
  z-index: 50;

  .title-text {
    font-family: "Caveat", cursive;
    margin-left: 5px;
    font-size: 15px;
    line-height: 16px;
    color: #132d7a;
    text-align: center;
  }
}
</style>
